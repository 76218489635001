// extracted by mini-css-extract-plugin
export var Article = "style-module--Article--L2H9i";
export var BackArrow = "style-module--BackArrow--3pNSQ";
export var Banner = "style-module--Banner--hpbhU";
export var BannerCaption = "style-module--BannerCaption--Cj4Hi";
export var BannerImage = "style-module--BannerImage--zE2iw";
export var Body = "style-module--Body--RdEZ4";
export var Breadcrumb = "style-module--Breadcrumb--mkkwW";
export var Category = "style-module--Category--zQtsx";
export var Content = "style-module--Content--VV9ap";
export var Details = "style-module--Details--XLlNv";
export var Footer = "style-module--Footer--B813r";
export var Header = "style-module--Header--9Ys5P";
export var Keyword = "style-module--Keyword--OIfPb";
export var ReadingTime = "style-module--ReadingTime--coIi2";